import { ThemeProvider } from 'emotion-theming';
import { NextPageContext } from 'next';
import { AppProps } from 'next/app';
import Head from 'next/head';
import * as React from 'react';
import theme from '@circleci/theme';
import { CompassThemeProvider } from '@circleci/compass';
import { QueryClient, QueryClientProvider } from 'react-query';
import isServer from '~/utils/isServer';
import '~/utils/realUserMonitoring'; // Initialize datadogRUM
import OptimizelyFullstackProvider from '~/engagement-experiments/personalizedLandingPage/OptimizelyFullstackProvider';

interface Props extends AppProps {
  ctx: NextPageContext;
}

const queryClient = new QueryClient();

const WebUI = ({ Component, pageProps, router }: AppProps) =>
  isServer ? null : (
    <QueryClientProvider client={queryClient} contextSharing={true}>
      <OptimizelyFullstackProvider>
        <Head>
          <title>CircleCI</title>
        </Head>
        <CompassThemeProvider>
          <ThemeProvider theme={theme}>
            <Component {...pageProps} router={router} />
          </ThemeProvider>
        </CompassThemeProvider>
      </OptimizelyFullstackProvider>
    </QueryClientProvider>
  );

export default WebUI;
