import { useQuery } from 'react-query';
import unfetch from 'unfetch';
import windowHasFetch from '~/utils/windowHasFetch';
import convertReactQueryErrorToError from '~/utils/convertReactQueryErrorToError';

type UserRole =
  | 'Software Engineer'
  | 'Engineering Manager'
  | 'DevOps Manager'
  | 'QA Engineer'
  | 'Student'
  | 'Educator'
  | 'Other'
  | string;

interface APIResponse {
  user_analytics_id: string;
  role: UserRole;
  use_case: string;
  eng_size: string;
  purpose: string;
}

interface Response {
  userRole: UserRole;
  userAnalyticsId: string;
  loading: boolean;
  error: Error | undefined;
}

const useUserRole = (): Response => {
  const url = 'https://bff.circleci.com/private/growth/user-attributes';
  // defining fetch inside of the hook so that it can be evaluated at call time.
  // this makes it much easier to mock out in our tests.
  const fetch = windowHasFetch() ? window.fetch : unfetch;

  const { isLoading: loading, error, data } = useQuery<APIResponse>(
    url,
    async () => {
      const res = await fetch(url, { method: 'GET', credentials: 'include' });

      if (!res.ok) {
        throw new Error(`received non 2xx from ${url}`);
      }

      return await res.json();
    },
    { retry: false },
  );

  return {
    userRole: data?.role,
    userAnalyticsId: data?.user_analytics_id,
    loading,
    error: convertReactQueryErrorToError(error),
  };
};

export default useUserRole;
