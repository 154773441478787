import ExperimentProvider from '@circleci/react-experiments/build/utils/ExperimentProvider';
import * as React from 'react';
import useUserRole from './hooks/useUserRole';

interface Props {
  children: React.ReactNode;
}

export default ({ children }: Props) => {
  if (!process.env.OPTIMIZELY_SDK_KEY) {
    throw new Error('OPTIMIZELY_SDK_KEY is required');
  }
  const { userAnalyticsId } = useUserRole();

  return (
    <ExperimentProvider me={{ id: userAnalyticsId }}>
      {children}
    </ExperimentProvider>
  );
};
